var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "single-step-calculator",
        {
          "single-step-calculator--local": _vm.LOCAL,
        },
      ],
    },
    [
      _c(
        "zg-transition-fade",
        { on: { "after-transition": _vm.scrollToStep2 } },
        [
          _vm.showForm
            ? _c("single-step-application", { attrs: { "start-on-step": 2 } })
            : _c("lazy-hydrate", { attrs: { "when-idle": "" } }, [
                _c(
                  "div",
                  {
                    class: [
                      "calculator",
                      {
                        "calculator--local": _vm.LOCAL,
                      },
                    ],
                  },
                  [
                    _vm.showTranslation
                      ? _c("language-translation", {
                          key: "language-translation",
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("calculator", { key: "calculator" }),
                    _vm._v(" "),
                    _c("buttons", {
                      key: "buttons",
                      attrs: { "submit-label": _vm.content.next },
                      on: { submit: _vm.submit },
                    }),
                    _vm._v(" "),
                    _c("usps", {
                      key: "usps",
                      attrs: { usps: _vm.content.usps },
                    }),
                    _vm._v(" "),
                    _c("disclaimer", { key: "disclaimer" }),
                    _vm._v(" "),
                    _c("trustpilot"),
                    _vm._v(" "),
                    _c("sticky-cta", {
                      attrs: { label: _vm.content.next, target: ".calculator" },
                    }),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.LOCAL
        ? _c("div", { staticStyle: { "padding-bottom": "1500px" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }